import { createServer } from 'miragejs'
import { getMetaHandler } from '@app/mockapi/integrations/getMeta'
import { handleOauthUserHandler } from '@app/mockapi/auth/handleOauthUser'
import { postStoreAppSurveyHandler } from '@app/mockapi/fastAppSurvey/postStoreAppSurveyHandler'
import { getSurveyDataHandler } from '@app/mockapi/fastAppSurvey/getSurveyDataHandler'
import { endpoints } from '@app/api/config'
import { handleOauthHandler } from '@app/mockapi/auth/handleOauth'
import { setupBridgeHandler } from '@app/mockapi/bbu/setupBridge'
import { getBridgeFields } from '@app/mockapi/nbee/getBridgeFields'
import { getBridgeById } from '@app/mockapi/nbee/getBridge'
import { getModules } from '@app/mockapi/nbee/getModules'
import { getAppAuthSchema } from '@app/mockapi/nbee/getAppAuthSchema'
import {
  genRedirectUri,
  getIntegrationsByAppId,
  getIntegrationsById,
  postAuthGrantCode,
} from '@app/mockapi/nbee/integrations'
import { getUserMe } from '@app/mockapi/user/getUser'
import { getPlans } from '@app/mockapi/billing/getPlans'
import { changePlanHandler } from '@app/mockapi/billing/changePlan'
import { checkAppCompatibility } from '@app/mockapi/nbee/checkAppCompatibility'
import { getCustomerGoogleAdsAccountBillingInfo } from '@app/mockapi/googleAdsAccount/getCustomerGoogleAdsAccountBillingInfo'
import { postCreateGoogleAdsAccount } from '@app/mockapi/googleAdsAccount/postCreateGoogleAdsAccount'
import { getCurrencies } from '@app/mockapi/utilities/getCurrencies'
import { getTimezones } from '@app/mockapi/utilities/getTimezones'
import { getUserGeolocation } from '@app/mockapi/utilities/getUserGeolocation'
import { getEmails } from '@app/mockapi/integrations/getEmails'
import { getIntegrationUsage } from '@app/mockapi/integrations/getIntegrationUsage'
import { postChooseIntegrationSettings } from '@app/mockapi/integrations/postChooseIntegrationSettings'

export const createServerInit = () => {
  console.info('Starting mirage mock server')

  return createServer({
    routes() {
      /* this.get(endpoints.integrationsMeta, getMetaHandler, { timing: 1000 })

      this.post(endpoints.handleOauthNotLoggedUser, handleOauthUserHandler, {
        timing: 1000,
      })
      this.post(endpoints.handleOauthLoggedUser, handleOauthHandler, {
        timing: 1000,
      })

      this.get(endpoints.fastAppSurveyData, getSurveyDataHandler, {
        timing: 500,
      })

      this.post(endpoints.storeAppSurvey, postStoreAppSurveyHandler, {
        timing: 2000,
      })

      this.post(endpoints.setupBridge, setupBridgeHandler, {
        timing: 2000,
      }) */

      // NBEE
      /* this.get(endpoints.getUserModules, getModules, {
        timing: 500,
      }) */
      /*  this.get(
        endpoints.getBridgeFields({ bridgeId: '321' }),
        getBridgeFields,
        {
          timing: 500,
        }
      ) */
      /*  this.get(endpoints.getBridgeById({ bridgeId: '321' }), getBridgeById, {
        timing: 500,
      }) */

      this.post(
        endpoints.chooseIntegrationSettings,
        postChooseIntegrationSettings,
        {
          timing: 1000,
        }
      )

      /* this.post(
        endpoints.getAppAuthSchemaByAppId({ appId: '*' }),
        getAppAuthSchema,
        {
          timing: 2000,
        }
      ) */
      /* this.get(endpoints.getIntegrationsByAppId, getIntegrationsByAppId, {
        timing: 500,
      }) */
      /* this.get(
        endpoints.getIntegrationById({ integrationId: '*' }),
        getIntegrationsById,
        {
          timing: 500,
        }
      ) */

      /* this.get(
        endpoints.getIntegrationUsage({ integrationId: '*' }),
        getIntegrationUsage,
        {
          timing: 500,
        }
      ) */

      // this.get(`${endpoints.checkAppsCompatibility}`, checkAppCompatibility)

      // User
      // this.get(endpoints.getUser, getUserMe)

      // billing
      // this.get(endpoints.getPlans, getPlans)
      // this.post(endpoints.changePlan, changePlanHandler, {
      //   timing: 4000,
      // })

      // this.get(endpoints.getVerifiedEmails, getEmails)

      /* this.post(endpoints.postAuthGrantCode, postAuthGrantCode, {
        timing: 2000,
      })

      this.post(endpoints.postGenRedirectUri, genRedirectUri, {
        timing: 2000,
      }) */

      // Google Ads Account
      /*  this.get(
        endpoints.getCustomerGoogleAdsAccountBillingInfo({
          customerId: '6798222368',
        }),
        getCustomerGoogleAdsAccountBillingInfo,
        {
          timing: 500,
        }
      )

      this.post(
        endpoints.postCreateGoogleAdsAccount,
        postCreateGoogleAdsAccount,
        {
          timing: 900,
        }
      ) */

      // Utilities
      /* this.get(endpoints.getCurrencies, getCurrencies, {
        timing: 300,
      }) */

      /* this.get(endpoints.getTimezones, getTimezones, {
        timing: 400,
      }) */

      /* this.get(endpoints.getUserGeolocation, getUserGeolocation, {
        timing: 300,
      }) */

      // All other API requests on the current domain will still pass through
      this.passthrough()

      // adding domains to skip
      this.passthrough('https://fg8vvsvnieiv3ej16jby.litix.io')
      this.passthrough('http://pipedream.wistia.com/*')
      this.passthrough('https://api.segment.io/*')
      this.passthrough('https://cdn.segment.com/*')
      this.passthrough(`${process.env.REACT_APP_V1_API}/**`)
      this.passthrough(`${process.env.REACT_APP_LARAVEL_API}/**`)
      this.passthrough('https://www.facebook.com/**')
      this.passthrough('https://www.web.facebook.com/**')
      this.passthrough('https://web.facebook.com/**')
      this.passthrough('https://cognito-idp.us-west-2.amazonaws.com/')
      this.passthrough('https://cognito-idp.us-west-2.amazonaws.com/**')
      this.passthrough(
        `${process.env.REACT_APP_COGNITO_URL}.amazoncognito.com/oauth2/token`
      )
    },
  })
}

export default createServerInit

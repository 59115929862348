import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const setCookie = (
  name: string,
  value: string,
  days: number = 1
): void => {
  const oneDay = 24 * 60 * 60
  const maxAge = days ? days * oneDay : oneDay
  cookies.set(name, value, {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
    maxAge: maxAge,
  })
}

export const getCookie = (name: string): string | undefined => {
  return cookies.get(name)
}

export type AffiliateIdDataFromCookies = {
  tapClickId: string | undefined
  tapVisitorId: string | undefined
}

/**
 * Fetches the Tapfiliate IDs from cookies.
 * @returns An object containing tapClickId and tapVisitorId or undefined if not found.
 */
export const getAffiliateIdFromCookies = ():
  | AffiliateIdDataFromCookies
  | undefined => {
  const tapId = getCookie('tap.id')
  const tapVid = getCookie('tap.vid')

  if (tapId && tapVid) {
    return {
      tapClickId: tapId,
      tapVisitorId: tapVid,
    }
  }
}

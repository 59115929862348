import React, { useEffect } from 'react'

const Close: React.FC = () => {
  useEffect(() => {
    window.close()
  }, [])

  return <div></div>
}

export default Close

import { Dispatch } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleVerticalSidebar } from '../../../store/actions/ApplicationConfigurationActions'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'

const MenuStyled = styled(Menu)`
  justify-content: space-between;
  .steps {
    flex: 1;
    margin-top: 0;
    border: 0;
    .step {
      padding: 5px;
    }
  }
  .item {
    padding: 10px;
    flex: 1 !important;
  }
`

const FbTopBar: React.FC = ({ children }) => {
  const dispatch: Dispatch<any> = useDispatch()

  return (
    <MenuStyled pointing secondary>
      <Menu.Item onClick={() => dispatch(toggleVerticalSidebar())}>
        <img
          src={
            'https://leadsbridge.com/app/assets/img/logos/logo-ws4.svg?v=2.12.19'
          }
          style={{ width: '125px' }}
          alt={'LeadsBridge'}
        />
      </Menu.Item>

      {children}

      {/*
            <Menu.Menu position='right' style={{verticalAlign: 'right'}}>
                <Menu.Item
                    style={{height: '100%'}}
                    onClick={() => {
                        window.location.href = window.location.origin + "/logout";
                        //history.push("/logout");
                    }}
                    name='logout'
                />
            </Menu.Menu>
            */}
    </MenuStyled>
  )
}

export default FbTopBar

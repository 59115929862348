import { ApiIntegrationListenerStatus } from '@app/@typings/Api/integration'
import {
  IntegrationListenerActionTypes,
  IntegrationListenerState,
  SET_INTEGRATION_LISTENER,
  UNSET_INTEGRATION_LISTENER,
} from '../types/IntegrationListenerTypes'
import { PusherEventData } from '@app/hooks/usePusherChannel'

export const setIntegrationListenerAction = (
  integrationListener: IntegrationListenerState
) => {
  return {
    type: SET_INTEGRATION_LISTENER,
    payload: integrationListener.data,
  }
}

export const unsetIntegrationListenerAction = () => {
  return {
    type: UNSET_INTEGRATION_LISTENER,
  }
}

export const setIntegrationListenerFields = (
  pusherEventData: PusherEventData
) => {
  return {
    type: 'SET_INTEGRATION_LISTENER_FIELDS',
    payload: pusherEventData,
  }
}

export const setIntegrationListenerStatus = (
  status: ApiIntegrationListenerStatus
): IntegrationListenerActionTypes => {
  return {
    type: 'SET_INTEGRATION_LISTENER_STATUS',
    payload: status,
  }
}

export const Messages = {
  AppInvoked: 'app_invoked',
  UserCreated: 'account_created',
  UserLogged: 'account_logged_in',
  PlatformInvoked: 'platform_invoked',
  PlatformError: 'platform_error',
  PlatformAuthorized: 'platform_authorized',
  PlatformCompleted: 'platform_completed',
}

export const doPostMessage = (event: any) => {
  const timestamp = Date.now()
  const message = JSON.stringify({
    timestamp: timestamp,
    event: event,
  })
  window.parent.postMessage(message, '*')
}

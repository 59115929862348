import { BbuRouteParams, LogoutRouteParams } from 'LeadsBridgeApp'
import { serializeQueryString } from '@app/utils/seralizeQueryString'

export const signinBasePath = 'signin'

export const makeQueryStringForLogout = ({
  redirectTo,
  toastMessage,
}: LogoutRouteParams) => {
  // prepare path, if `redirectTo` exists or we set empty object
  const path = redirectTo ? { path: redirectTo } : {}

  // prepare message object ore return empty one is toast is not passed
  const message =
    toastMessage && toastMessage.message
      ? {
          message: btoa(JSON.stringify(toastMessage)),
        }
      : {}

  // we serialize our objects into a query string
  const serializedQs = serializeQueryString({
    obj: {
      ...path,
      ...message,
    },
  })
  return serializedQs ? `?${serializedQs}` : ''
}

export default {
  signup: {
    path: `/signup`,
    makeUrl: () => `/signup`,
  },
  signupBbu: {
    path: `/bc/lb/:source/:destination`,
    makeUrl: ({
      source,
      destination,
    }: {
      source: string
      destination: string
    }) => `/bc/lb/${source}/${destination}`,
  },
  signupBbuFallbackSurvey: {
    path: `/signup-survey/:partnerName/:source/:destination`,
    makeUrl: ({ partnerName, source, destination }: BbuRouteParams) =>
      `/signup-survey/${partnerName}/${source}/${destination}`,
  },
  signupPartner: {
    path: `/deals/:partner/:couponCode?`,
    makeUrl: () => `/deals/:partner/:couponCode`,
  },
  signin: {
    path: `/${signinBasePath}`, // --> /signin
    makeUrl: () => `/${signinBasePath}`,
  },
  forgotPassword: {
    path: `/${signinBasePath}/forgotpassword`,
    makeUrl: () => `/${signinBasePath}/forgotpassword`,
  },
  changePassword: {
    path: `/changepassword`,
    makUrl: () => `/changepassword`,
  },
  saml: {
    path: `/${signinBasePath}/saml`,
    makeUrl: () => `/${signinBasePath}/saml`,
  },
  samlRedirect: {
    path: `/auth/redirect`,
    makeUrl: () => `/auth/redirect`,
  },
  logout: {
    path: `/logout`,
    makeUrl: ({ redirectTo, toastMessage }: LogoutRouteParams) => {
      const queryString = makeQueryStringForLogout({ redirectTo, toastMessage })
      return `/logout${queryString ? `?${queryString}` : ''}`
    },
  },
}

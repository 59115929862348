import nbeeRoutes from '@app/routes/byFeature/nbee'
import integrationRoutes from '@app/routes/byFeature/integration'
import billingRoutes from '@app/routes/byFeature/billing'
import bbuRoutes from '@app/routes/byFeature/bbu'
import facebookRoutes from '@app/routes/byFeature/facebook'
import authRoutes from '@app/routes/byFeature/auth'
import googleAdsAccountRoutes from '@app/routes/byFeature/googleAdsAccount'
import adminRoutes from '@app/routes/byFeature/admin'

// How to use routes:
// When patter matching is required (eg: react router <Route path='..'>) use appRoutes.<routeName>.path
// When you need to render the url (eg: <Link to='..' >, then use the appRoutes.<routeName>.markeUrl()
export const appRoutes = {
  ...authRoutes, // signin, signup, saml, etc...
  ...bbuRoutes, // bbu and callbacks
  ...facebookRoutes, // Fbe and AppStore
  ...nbeeRoutes,
  ...googleAdsAccountRoutes, // Google Ads Account Feature
  ...integrationRoutes,
  ...billingRoutes,
  ...adminRoutes,
}

export const bridgeBuilderStep1Paths = [
  appRoutes.nbeeStep1New.path,
  appRoutes.nbeeStep1Edit.path,
  appRoutes.nbeeAppBbu.path,
  appRoutes.signupBbu.path,
]

// this is meant to be used to match path for bbu parter only BBUs.
// Example /bc/fb or /ebc/fc, but not to be used for /bc/lb/ since /lb/ is not a partner
export const bridgeByUrlPartnersPaths = [
  appRoutes.bridgeByUrl.path,
  appRoutes.bridgeByUrlEmbedded.path,
]

import { createGlobalStyle } from 'styled-components'

export const AnimationsUtils = createGlobalStyle`
  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }

    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }

  @keyframes fillProgressBar {
    from {width: 1%;}
    to {width: 100%;}
  }
`

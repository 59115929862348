export const sleep = (timeMs: number) =>
  new Promise((resolve) => setTimeout(resolve, timeMs))

export const openZendeskChat = (zE: any) => {
  zE('webWidget', 'show')
  zE('webWidget', 'open')
  zE('webWidget:on', 'close', function () {
    zE('webWidget', 'hide')
  })
}

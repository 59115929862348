import React from 'react'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { NbeeStepCompletedState } from '@app/pages/Nbee/Step3'
import { isDebug } from '@app/utils/envUtils'

export const NbeeHome: React.FC = () => {
  const history = useHistory()

  if (!isDebug) {
    // prevent this page to appear in production :)
    return null
  }

  return (
    <div>
      <h1>NBEE Home</h1>

      <ul>
        <li>
          <button
            onClick={() => {
              history.push(appRoutes.nbeeBridgeChooserStep1.makeUrl())
            }}
          >
            Bridge Chooser Step 1
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push(
                appRoutes.nbeeBridgeChooserStep2.makeUrl({
                  sourceAppId: '63',
                  destinationAppId: '45',
                })
              )
            }}
          >
            Bridge Chooser Step 2
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push(appRoutes.nbeeStep1New.makeUrl())
            }}
          >
            New bridge empty
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push({
                pathname: appRoutes.nbeeStep1New.makeUrl(),
                search: 'sourceId=63',
              })
            }}
          >
            New bridge empty with sourceId selected
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              // redirect to NBEE form with source and destination prefilled
              history.push(
                appRoutes.signupBbu.makeUrl({
                  source: 'facebook-lead-ads',
                  destination: 'google-sheets',
                })
              )
            }}
          >
            New bridge form with BBU
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push(appRoutes.nbeeStep1Edit.makeUrl('321'))
            }}
          >
            Edit bridge
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push(appRoutes.nbeeStep2.makeUrl('321'))
            }}
          >
            Step2
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              const step3State: NbeeStepCompletedState = { success: true }
              history.push(appRoutes.nbeeStep3.makeUrl('321'), step3State)
            }}
          >
            Step3
          </button>
        </li>

        <li>
          <button
            onClick={() => {
              history.push('/test-components')
            }}
          >
            Test Components
          </button>
        </li>
      </ul>
    </div>
  )
}

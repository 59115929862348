export const serializeQueryString = function ({
  obj,
  prefix,
}: {
  obj: Record<string, any>
  prefix?: string
}): string {
  const str = []
  let p
  for (p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p
      const v = obj[p]
      str.push(
        v !== null && typeof v === 'object'
          ? serializeQueryString({
              obj: v,
              prefix: k,
            })
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      )
    }
  }
  return str.join('&')
}

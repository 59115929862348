import { createGlobalStyle } from 'styled-components'

// !important everywhere!!! We know and we'll get rid of semantic at some point.
// Until then, this is the only way

export const CommonCss = createGlobalStyle`
  html, body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px!important;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600;
  }
  
  /* Fix last pass plugin */
  input[autocomplete="off"]:-webkit-autofill{
    display: block !important
  }
  img[id^="__lpform_"]{
    cursor: pointer !important;
  }
  .gtx-trans-icon{
    display: none;
  }
`

import { BbuRouteParams, BridgeChooserStep2RouteParams } from 'LeadsBridgeApp'

const nbeeBasePath = 'nbee'

export default {
  nbee: {
    path: `/${nbeeBasePath}`,
    makeUrl: () => `/${nbeeBasePath}`,
  },
  nbeeBridgeChooserStep1: {
    path: `/${nbeeBasePath}/bridge`,
    makeUrl: () => `/${nbeeBasePath}/bridge`,
  },
  nbeeBridgeChooserStep2: {
    path: `/${nbeeBasePath}/bridge/select-editor/:sourceAppId/:destinationAppId`,
    makeUrl: ({
      sourceAppId,
      destinationAppId,
    }: BridgeChooserStep2RouteParams) =>
      `/${nbeeBasePath}/bridge/select-editor/${sourceAppId}/${destinationAppId}`,
  },
  nbeeStep1New: {
    path: `/${nbeeBasePath}/bridge/new`,
    makeUrl: () => `/${nbeeBasePath}/bridge/new`,
  },
  nbeeAppBbu: {
    path: `/bc/app/:source/:destination`,
    makeUrl: ({ source, destination }: BbuRouteParams) =>
      `/bc/app/${source}/${destination}`,
  },
  nbeeStep1Edit: {
    path: `/${nbeeBasePath}/bridge/edit/:bridgeId`,
    makeUrl: (bridgeId: string) => `/${nbeeBasePath}/bridge/edit/${bridgeId}`,
  },
  nbeeStep2: {
    path: `/${nbeeBasePath}/bridge/fields-mapping/:bridgeId`,
    makeUrl: (bridgeId: string) =>
      `/${nbeeBasePath}/bridge/fields-mapping/${bridgeId}`,
  },
  nbeeStep3: {
    path: `/${nbeeBasePath}/bridge/status/:bridgeId`,
    makeUrl: (bridgeId: string) => `/${nbeeBasePath}/bridge/status/${bridgeId}`,
  },
  nbeeInfoPage: {
    path: `/${nbeeBasePath}/bridge/status/info`,
    makeUrl: () => `/${nbeeBasePath}/bridge/status/info`,
  },
}

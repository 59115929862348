import axios from 'axios'
// import Cookies from 'universal-cookie'

export const apiPublicClient = axios.create()

apiPublicClient.interceptors.request.use(async (config) => {
  config.headers['Content-Type'] = 'application/json'
  // const cookies = new Cookies()
  //
  // const xsrfToken = cookies.get('XSRF-TOKEN')
  // if (xsrfToken) {
  //   config.headers['X-Xsrf-Token'] = xsrfToken
  // }
  return config
})

import {
  ApiIntegrationListener,
  ApiIntegrationListenerField,
} from '@app/@typings/Api/integration'

// Action types
export const SET_INTEGRATION_LISTENER = 'SET_INTEGRATION_LISTENER'
export const SET_INTEGRATION_LISTENER_FIELDS = 'SET_INTEGRATION_LISTENER_FIELDS'
export const SET_INTEGRATION_LISTENER_STATUS = 'SET_INTEGRATION_LISTENER_STATUS'
export const UNSET_INTEGRATION_LISTENER = 'UNSET_INTEGRATION_LISTENER'

// State
export type IntegrationListenerState = {
  data: ApiIntegrationListener | null
}

// Actions
interface SetIntegrationListener {
  type: typeof SET_INTEGRATION_LISTENER
  payload: ApiIntegrationListener
}

interface SetIntegrationListenerFields {
  type: typeof SET_INTEGRATION_LISTENER_FIELDS
  payload: ApiIntegrationListenerField[]
}

interface SetIntegrationListenerStatus {
  type: typeof SET_INTEGRATION_LISTENER_STATUS
  payload: 0 | 1 | 2 | 3
}

// Combine them into an 'any' union type
export type IntegrationListenerActionTypes =
  | SetIntegrationListener
  | SetIntegrationListenerFields
  | SetIntegrationListenerStatus

import { createReducer } from '@reduxjs/toolkit'
import {
  FbAppStoreState,
  SET_PLATFORM_ID,
  SET_STATUS,
} from '../types/FbAppStoreTypes'
import { AppStoreStatus } from '../../enums/AppStoreStatus'

const initialState: FbAppStoreState = {
  status: AppStoreStatus.NONE,
  platformId: 0,
}

export const FbAppStoreReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_STATUS, (state: FbAppStoreState, action: any) => {
      state.status = action.payload
    })
    .addCase(SET_PLATFORM_ID, (state: FbAppStoreState, action: any) => {
      state.platformId = action.payload
    })
})

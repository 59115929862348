import React from 'react'

// 63
// 491
const FbAppStoreTest: React.FC = () => {
  return (
    <div className={'mx-auto'}>
      <iframe
        src={'http://localhost:3000/fbAppStore?crm_id=491'}
        height={650}
        width={1500}
      />
    </div>
  )
}

export default FbAppStoreTest

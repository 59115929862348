import {
  css,
  DefaultTheme,
  ThemeProps,
  FlattenInterpolation,
} from 'styled-components'

type CustomStyledCss = FlattenInterpolation<ThemeProps<DefaultTheme>>

// we consider embeddable everything between this tuple
const iframeWidthRange: [number, number] = [768, 800]

const minWidth = `${iframeWidthRange[0]}px`
const maxWidth = `${iframeWidthRange[1]}px`
const maxWidthUp = `${iframeWidthRange[1] + 1}px` // 801

export const mediaIframeOnly = (customCss: CustomStyledCss) => css`
  @media (min-width: ${minWidth}) and (max-width: ${maxWidth}) {
    ${customCss}
  }
`

export const mediaIframeUp = (customCss: CustomStyledCss) => css`
  @media (min-width: ${maxWidthUp}) {
    ${customCss}
  }
`

import React from 'react'
import {
  MultiCreatableCustom,
  SelectValueMulti,
} from '@components/Form/MultiCreatableCustom'
import { MappedField } from 'Nbee'
export const TestComponents: React.FC = () => {
  const isProduction = process.env.NODE_ENV !== 'development'
  if (isProduction) {
    // prevent this page to appear in production :) test
    return null
  }

  const mockedSourceOptions: SelectValueMulti[] = [
    {
      value: '2022-02-02____Cell R1C30',
      label: '2022-02-02 ›› creationDate',
      fieldType: 'source',
    },
    {
      value: '2022-02-02____Cell R1C29',
      label: '2022-02-02 ›› leads.bothEarsHearingLoss',
      fieldType: 'source',
    },
    {
      value: '2022-02-02____Cell R1C3',
      label: '2022-02-02 ›› leads.city',
      fieldType: 'source',
    },
    {
      value: '2022-02-02____Cell R1C6',
      label: '2022-02-02 ›› leads.country',
      fieldType: 'source',
    },
  ]

  const mockedPicklistOptions: SelectValueMulti[] = [
    {
      value: '0',
      label: 'NO',
      fieldType: 'custom',
    },
    {
      value: '1',
      label: 'YES',
      fieldType: 'custom',
    },
  ]

  const mockedMappedField: MappedField = {
    destinationFieldId: 'opt_out',
    mappingType: 'manual',
    destinationText: '',
    destinationFieldLabel: 'Opt Out',
    isRequired: false,
    testLeadValue: '',
    picklist: [
      {
        id: '0',
        text: 'NO',
      },
      {
        id: '1',
        text: 'YES',
      },
    ],
    mapping: [],
  }

  return (
    <div>
      <MultiCreatableCustom
        sourceOptions={mockedSourceOptions}
        picklistOptions={mockedPicklistOptions}
        mappedField={mockedMappedField}
        onMappingChange={() => null}
      />
    </div>
  )
}

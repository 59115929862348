import {
  GoogleAdsAccountRouteParams,
  IntegrationCredentialEditParams,
} from 'LeadsBridgeApp'

export default {
  googleAdsAccountFormCreate: {
    path: `/google-ads-account/create`,
    makeUrl: () => `/google-ads-account/create`,
  },

  googleAdsAccountFormCreated: {
    path: `/google-ads-account/create/:customerId`,
    makeUrl: ({ customerId }: GoogleAdsAccountRouteParams) =>
      `/google-ads-account/create/${customerId}`,
  },
  googleAdsAccountActivate: {
    path: `/google-ads-account/activate`,
    makeUrl: () => `/google-ads-account/activate`,
  },
  googleAdsAccountConnect: {
    path: `/google-ads-account/connect`,
    makeUrl: () => `/google-ads-account/connect`,
  },
}

import React, { useEffect, useState } from 'react'
import { IntegrationCredentialsContent } from '@features/nbee/IntegrationCredentialsContent'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { IntegrationCredentialEditParams } from 'LeadsBridgeApp'
import {
  makeIntegrationFormInitialValues,
  transformIntegrationFormValuesToApiSaveRequestBody,
} from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/utils'
import { IntegrationFormValues } from 'Nbee'
import { ApiErrorResponse } from 'BackendApi'
import { FormikHelpers } from 'formik'
import { useAppDispatch } from '@app/store/hooks'
import { parseApiError } from '@app/api/utils/error'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { Loader } from '@components/Basic/Loader'
import { sendIntegrationDataToParentWindow } from '@features/nbee/IntegrationCredentialsContent/utils'
import { Message } from '@components/Basic/Message'
import { useGetIntegrationById } from '@app/api/getIntegrationById'
import { useUpdateIntegration } from '@app/api/updateIntegration'
import { useGetAppById } from '@app/api/getAppById'
import { appRoutes } from '@app/routes'
import { useRetrieveAppCredentials } from '@app/api/postRetrieveAppCredentials'
import { SubmitAction } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm'
import { usePostNewIntegration } from '@app/api/postNewIntegration'
import { useTestIntegrationCredentialsById } from '@app/api/testIntegrationCredentialsById'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@app/dataTracking'

// This page is supposed to be always opened inside a popup window with reduced dimensions
// In future we might add an additional check and prevent the component to render if `window.opener` is not set
// this means that this component has not been mounted in a page generated from a parent window
export const IntegrationAuthScreenEdit: React.FC = () => {
  const { t } = useTranslation()
  const { appId, integrationId, bridgeId } =
    useParams<IntegrationCredentialEditParams>()
  const appIdNumber = parseInt(appId, 10)
  const [newIntegration, setNewIntegration] =
    useState<{ id: number; name: string }>()
  // this will be used to keep the loading spinner while sending data to parent and modal is about to be closed
  const [isAboutToClosingModal, setIsAboutToClosingModal] = useState(false)

  const dispatch = useAppDispatch()
  const location = useLocation()
  const isSaveStep = matchPath(location.pathname, {
    path: appRoutes.integrationAuthScreenEditStep2.path,
    exact: true,
    strict: true,
  })

  const {
    data: appData,
    isLoading: isLoadingApp,
    error: errorApiGetApp,
  } = useGetAppById(appId)

  const {
    data: appAuthSchemaResponse,
    mutate: retrieveAuthSchema,
    isLoading: isLoadingAppAuthSchema,
    error: errorApiAppAuthSchema,
  } = useRetrieveAppCredentials()

  const {
    data: existingIntegration,
    isLoading: isLoadingExistingIntegration,
    error: errorApiGetIntegration,
  } = useGetIntegrationById(integrationId)

  const {
    mutate: updateIntegration,
    isLoading: isUpdatingIntegration,
    error: integrationUpdateApiError,
    data: updatedIntegrationData,
  } = useUpdateIntegration()

  const {
    mutate: saveNewIntegration,
    isLoading: isSavingNewIntegration,
    error: integrationSaveApiError,
    data: savedIntegrationData,
  } = usePostNewIntegration()

  const {
    data: testIntegrationResults,
    error: integrationTestError,
    isLoading: isCheckingValidIntegration,
    isRefetching: isReCheckingValidIntegration,
    refetch: reTestValidIntegration,
  } = useTestIntegrationCredentialsById(newIntegration?.id)

  const trackEventError = (
    apiError: ApiErrorResponse,
    formValues: IntegrationFormValues
  ) => {
    const parsedError = parseApiError(apiError)
    trackEvent({
      eventName: 'UnexpectedErrorThrown',
      feature: 'NBEE',
      step: 'Apps',
      params: {
        appId: appIdNumber,
        appName: appData?.name,
        integrationId: parseInt(integrationId, 10),
        integrationName: formValues.name,
        custom: {
          errorCode: parsedError.code,
          errorDescription: parsedError.message,
        },
      },
      sendEventToIntercom: true,
    })
  }

  // if error we notify the user
  useEffect(() => {
    const error = integrationUpdateApiError || integrationSaveApiError
    if (error) {
      const errorMessage = parseApiError(error).message
      dispatch(
        sendToast({
          title: 'Error',
          message: errorMessage,
          color: 'negative',
        })
      )
    }
  }, [integrationUpdateApiError, integrationSaveApiError])

  // retrieving auth schema on mount
  useEffect(() => {
    retrieveAuthSchema({
      appId: appIdNumber,
      settings: [],
    })
  }, [appIdNumber])

  // data just saved
  const optimisticIntegrationData =
    updatedIntegrationData || savedIntegrationData?.data?.integration

  // we build the full auth schema by merging existing data into the full app auth schema
  const appAuthSchema = appAuthSchemaResponse?.data?.credentials.fields || []
  const isOauthUri = appAuthSchemaResponse?.data?.credentials.type === 'oauth2'
  const initialValues = appData
    ? makeIntegrationFormInitialValues(
        appAuthSchema,
        appData.name,
        optimisticIntegrationData || existingIntegration
      )
    : null

  // on mount events
  const isReady = Boolean(appData && initialValues && appAuthSchemaResponse)
  useEffect(() => {
    if (isReady && !isSaveStep) {
      trackEvent({
        eventName: 'EditIntegrationShown',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: appData?.id,
          appName: appData?.name,
          integrationId: parseInt(integrationId, 10),
          integrationName: initialValues?.name,
          oauth: isOauthUri,
        },
      })
    }
  }, [isReady])

  const updateIntegrationHandler = (
    formValues: IntegrationFormValues,
    formikHelpers: FormikHelpers<IntegrationFormValues>,
    action: SubmitAction
  ) => {
    formikHelpers.setSubmitting(true)

    if (action === 'update') {
      updateIntegration(
        {
          integrationId: integrationId,
          requestBody: transformIntegrationFormValuesToApiSaveRequestBody(
            formValues,
            appIdNumber
          ),
        },
        {
          onError: (apiError) => {
            formikHelpers.setSubmitting(false)
            trackEventError(apiError, formValues)
          },
        }
      )
    }

    if (action === 'save-new') {
      saveNewIntegration(
        {
          requestBody: transformIntegrationFormValuesToApiSaveRequestBody(
            formValues,
            appIdNumber
          ),
        },
        {
          onError: (apiError) => {
            formikHelpers.setSubmitting(false)
            trackEventError(apiError, formValues)
          },
        }
      )
    }
  }

  // handle success save/updated
  useEffect(() => {
    const isSuccessResponse =
      updatedIntegrationData || savedIntegrationData?.data?.integration
    if (isSuccessResponse) {
      const integrationId = isSuccessResponse.id
      const integrationName = isSuccessResponse.name
      if (newIntegration?.id) {
        // in case `newIntegration` is already set the `useTestIntegrationCredentialsById` hook
        // will not re-run, so re refetch data manually
        reTestValidIntegration()
      }
      setNewIntegration({ id: integrationId, name: integrationName })
    }
  }, [updatedIntegrationData, savedIntegrationData])

  // Proceeding when test is successful
  useEffect(() => {
    if (testIntegrationResults?.data?.result && newIntegration) {
      setIsAboutToClosingModal(true)

      if (!isOauthUri) {
        // when test is OK and we are not in oauth flow, before sending the SaveClicked Event, we need to send
        // the `AuthorizeSuccess` so we can simulate the same events order of the oauth integration
        trackEvent({
          eventName: 'AuthorizeSuccess',
          feature: 'NBEE',
          step: 'Integration',
          params: {
            appId: appData?.id,
            appName: appData?.name,
            integrationId: newIntegration.id,
            integrationName: newIntegration.name,
            oauth: false,
          },
        })
      }

      trackEvent({
        eventName: 'SaveClicked',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: appData?.id,
          appName: appData?.name,
          integrationId: newIntegration.id,
          integrationName: newIntegration.name,
          oauth: isOauthUri,
        },
      })

      sendIntegrationDataToParentWindow(newIntegration.id, newIntegration.name)
    }
  }, [testIntegrationResults])

  // In case of test failure
  useEffect(() => {
    // in case test fails we send the `AuthorizeFailure` tracking event only if is not an oauth flow
    // for oauth integrations we send this event during the oauth flow, insice IntegrationAuthCallback component
    if (integrationTestError && !isOauthUri) {
      const parsedTestError = parseApiError(integrationTestError)
      trackEvent({
        eventName: 'AuthorizeFailure',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: appData?.id,
          appName: appData?.name,
          integrationId: newIntegration?.id,
          integrationName: newIntegration?.name,
          oauth: false,
          custom: {
            errorCode: parsedTestError.code,
            errorDescription: parsedTestError.message,
          },
        },
        sendEventToIntercom: true,
      })
    }
  }, [integrationTestError])

  const isLoadingAll =
    isLoadingAppAuthSchema ||
    isLoadingApp ||
    isLoadingExistingIntegration ||
    isCheckingValidIntegration ||
    isReCheckingValidIntegration ||
    isAboutToClosingModal
  const apiError =
    errorApiAppAuthSchema || errorApiGetIntegration || errorApiGetApp

  if (!appId) {
    return <div>Missing Parameter</div>
  }

  return isLoadingAll ? (
    <div
      style={{
        position: 'relative',
        height: 200,
      }}
    >
      <Loader $active $dimmer $size={'large'} />
    </div>
  ) : appData && initialValues ? (
    <IntegrationCredentialsContent
      appId={appIdNumber}
      appName={appData.name}
      appLogoUri={appData.logoUriSmall}
      isSavingApi={isUpdatingIntegration || isSavingNewIntegration}
      onSubmit={updateIntegrationHandler}
      isOauthUri={isOauthUri}
      initialValues={initialValues}
      bridgeId={bridgeId ? parseInt(bridgeId, 10) : undefined}
      skipParams={Boolean(isSaveStep && isSaveStep.isExact)}
      integrationId={parseInt(integrationId, 10)}
      isNotValidIntegrationErrorMessage={
        integrationTestError
          ? parseApiError(integrationTestError).message
          : testIntegrationResults?.data?.result === false
          ? testIntegrationResults.data.message ||
            t('api.error.genericNoMessageReceived')
          : undefined
      }
    />
  ) : apiError ? (
    <Message $status={'error'}>{parseApiError(apiError).message}</Message>
  ) : null
}

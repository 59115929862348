import { Auth } from 'aws-amplify'
import CognitoApiService from './CognitoApiService'
import { sendToast } from '../store/actions/ApplicationConfigurationActions'
import AuthService from './AuthService'

export default class FacebookService {
  static initFB = () => {
    const fb = window.FB
    // @ts-ignore
    // console.log('FB SDK initialized');
  }

  static fbAsyncInit = () => {
    // init the fb sdk client
    const fb = window.FB
    fb.init({
      appId: '939660826071336',
      cookie: true,
      xfbml: true,
      version: 'v2.11',
    })
  }

  static createScriptFb = () => {
    // load the sdk
    // @ts-ignore
    window.fbAsyncInit = FacebookService.fbAsyncInit
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.async = true
    script.onload = FacebookService.initFB
    document.body.appendChild(script)
  }

  // ASYNC
  // static createScriptFbAsync = () =>
  //   new Promise((resolve) => {
  //     // load the sdk
  //     const script = document.createElement('script')
  //     script.src = 'https://connect.facebook.net/en_US/sdk.js'
  //     script.async = true
  //     script.onload = () => {
  //       FacebookService.fbAsyncInit()
  //       resolve(null)
  //     }
  //     document.body.appendChild(script)
  //   })

  // ASYNC
  // static getAccessTokenAsync = (): Promise<string> =>
  //   new Promise((resolve) => {
  //     const fb = window.FB
  //     fb.Event.subscribe('auth.authResponseChange', (response) => {
  //       console.log('response')
  //       const fbAccessToken = response.authResponse?.accessToken
  //       if (fbAccessToken) {
  //         // if `accessToken` exists we need to pass it to backend api
  //         resolve(fbAccessToken)
  //       }
  //     })
  //   })

  static getAWSCredentials = async (
    response: any,
    location: any,
    dispatch: any
  ) => {
    return new Promise((resolve, reject) => {
      const { accessToken, expiresIn } = response
      const date = new Date()
      const expires_at = expiresIn * 1000 + date.getTime()
      if (!accessToken) {
        return
      }
      // @ts-ignore
      const fb = window.FB
      // todo account id
      fb.api('/me', { fields: 'name,email' }, async (response: any) => {
        const user = {
          name: response.name,
          email: response.email,
        }

        await CognitoApiService.federatedToCognito(
          accessToken,
          'FACEBOOK',
          user.email,
          window.location.href
        )
          .then((r: any) => {
            const username = user.email
            const password = r.password
            Auth.signIn({
              username,
              password,
              validationData: [],
            }).then((user) => {
              AuthService.onLogin(false, dispatch)
            })
          })
          .catch((err) => {
            // console.log('Err');
            if (err.response.status === 400) {
              dispatch(
                sendToast({
                  title: 'Cannot login',
                  messages: [
                    'You are registered without a social account. Log in with your account credentials.',
                  ],
                  color: 'warning',
                })
              )
              reject()
            } else {
              dispatch(
                sendToast({
                  title: 'Error',
                  messages: [
                    err.response?.data ? err.response.data : 'Unknown error',
                  ],
                  color: 'negative',
                })
              )
              reject()
            }
          })
      })
    })
  }

  static signInWithFacebook = async (location: any, dispatch: any) => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const fb = window.FB
      fb.getLoginStatus(async (response: any) => {
        if (response.status === 'connected') {
          // console.log('response status === connected');
          FacebookService.getAWSCredentials(
            response.authResponse,
            location,
            dispatch
          ).finally(() => resolve(true))
        } else {
          // console.log('response status:', response.status);
          await fb.login(
            async (response: any) => {
              if (!response || !response.authResponse) {
                reject()
                return
              }
              // console.log('facebook auth response', response.authResponse);
              FacebookService.getAWSCredentials(
                response.authResponse,
                location,
                dispatch
              ).finally(() => resolve(true))
            },
            {
              // the authorized scopes
              scope: 'public_profile,email',
            }
          )
        }
      })
    })
  }
}

import React, { useEffect, useState } from 'react'
import QueryString from 'querystring'
import LoginV1Service from '../services/ApplicationV1Bridge'
import { FacebookParameters } from 'LeadsBridgeApp'
import { Button, Dimmer, Loader, Message } from 'semantic-ui-react'
import createPersistedState from 'use-persisted-state'
import { useSelector } from 'react-redux'
import { RootState } from '../index'
import { AuthState } from '@aws-amplify/ui-components'

const Fbe: React.FC = () => {
  const [callInProgress, setCallInProgress] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const useRequestUriState = createPersistedState('requestUri')
  const [persistentUri, setPersistentUri] = useRequestUriState('')
  const usePersisteIsFlowForNbee = createPersistedState('isFlowForNbee')
  const [persistentIsFlowForNbee, setPersistentIsFlowForNbee] =
    usePersisteIsFlowForNbee(false)
  const authState = useSelector((state: RootState) => state.user.authState)

  useEffect(() => {
    const urlObj = new URL(String(window.location.href))
    const requestUri = urlObj.pathname
    setPersistentUri(requestUri)

    const url = process.env.REACT_APP_FACEBOOK_OAUTH
    const params = QueryString.parse(window.location.search.slice(1))
    const flowType: string = params.flowType as string
    const instanceId: string = params.instanceId as string

    // we need to persist this value, in order to read it in the fbe/callback (after redirect)
      // Test Deploy
    const nbee = params.nbee as string | undefined
    setPersistentIsFlowForNbee(nbee === 'true')

    const hasRequiredParams = flowType || requestUri
    if (hasRequiredParams && authState === AuthState.SignedIn) {
      LoginV1Service.facebookParameters(flowType, instanceId, requestUri)
        .then((res: FacebookParameters) => {
          const encodedExtras: string = encodeURIComponent(
            JSON.stringify(res.extras)
          )
          const backendUrl: string = encodeURIComponent(
            window.location.origin + '/fbe/callback'
          )

          let parameters = `&client_id=` + res.fbAppId
          parameters += `&display=page`
          parameters += `&redirect_uri=${backendUrl}`
          parameters += `&response_type=code`
          parameters += `&scope=` + res.scopes

          const fb: string = `${url}?extras=${encodedExtras}` + parameters

          window.location.replace(fb)
        })
        .catch((error) => {
          // Found here https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
          const errorMessage = error.response?.data?.errorMessage
            ? error.response.data.errorMessage
            : '-'
          setErrorMessage(errorMessage)
        })
        .finally(() => {
          setCallInProgress(false)
        })
    } else if (hasRequiredParams && authState !== AuthState.SignedIn) {
      // here we are not ready to do api request, since user is still logging in
      // once `authState` will be updated they will be able to proceed as intended
    } else if (!hasRequiredParams) {
      setCallInProgress(false)
      setErrorMessage('Invalid Flow Type or requestUri')
    }
  }, [authState])

  return (
    <>
      <Dimmer active={callInProgress} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {errorMessage !== '' && (
        <Message negative>
          <Message.Header>An error occurred!</Message.Header>
          <p>{errorMessage}</p>
          {/*
           we can't rely on react router <Link />, since url/path is exaclty the same
           and click action will be ignored, so let's just refresh the page
           */}
          {persistentUri && (
            <Button primary onClick={() => window.location.reload()}>
              Retry
            </Button>
          )}
        </Message>
      )}
    </>
  )
}

export default Fbe

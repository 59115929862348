import React from 'react'
import { BridgeFormValues } from 'Nbee'
import { BridgeChooserForm } from '@features/nbee/BridgeChooserForm'
import { BridgeChooserRedirector } from '@features/nbee/BridgeChooserRedirector'
import { Route, Switch, useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'

const emptyBridge: BridgeFormValues = {
  name: `New bridge ${new Date().getTime()}`,
  source: {
    appId: undefined,
    integrationId: undefined,
    settings: [],
  },
  destination: {
    appId: undefined,
    integrationId: undefined,
    settings: [],
  },
  settings: {
    emailReceipt: {
      active: false,
    },
  },
}

export const BridgeChooser: React.FC = () => {
  const initialValues = emptyBridge
  const history = useHistory()

  return (
    <Switch>
      {/* Step 1: App selections */}
      <Route exact path={appRoutes.nbeeBridgeChooserStep1.path}>
        <BridgeChooserForm
          initialValues={initialValues}
          onSubmit={(formValues) => {
            // on submit we redirect to step 2
            history.push(
              appRoutes.nbeeBridgeChooserStep2.makeUrl({
                sourceAppId: `${formValues.source.appId}`,
                destinationAppId: `${formValues.destination.appId}`,
              })
            )
          }}
        />
      </Route>
      {/*
      Step 2: Editor selection
      > NB: For some combinations, there is no 'complex bridge'. Within this Route, we check compatibility and manage the automatic redirect!
      */}

      <Route
        exact
        path={appRoutes.nbeeBridgeChooserStep2.path}
        component={BridgeChooserRedirector}
      />
    </Switch>
  )
}

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

interface Props {}

const LegacyRedirect: React.FC<Props> = () => {
  const location = useLocation()

  useEffect(() => {
    const redirectUrl =
      process.env.REACT_APP_V1_URL! + location.pathname + location.search
    // some time redirect from v1 to v2 is not stripping out the '/app' part
    // that is already present in `process.env.REACT_APP_V1_URL`, causing
    // the computation of an URL of type `https://leadsbridge.com/app/` + `/app/path/to/page/`
    // This is something not related to v2 app, since some time it receives
    // `/bridges/edit/` and some times `app/page-abc/`.
    // We can fix this by replaceing `/app/app/` with a single `/app/` :D
    const parsedRedirectUrl = redirectUrl.replace('/app/app/', '/app/')
    window.location.href = parsedRedirectUrl
  }, [])

  return (
    <div style={{ minHeight: '100vh' }}>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </div>
  )
}

export default LegacyRedirect

import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UNSET_CURRENT_USER = 'UNSET_CURRENT_USER'
export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const UNSET_AUTH_STATE = 'UNSET_AUTH_STATE'

export interface UserState {
  auth: CognitoUserInterface | undefined
  authState: AuthState | undefined
}

interface SetCurrentUser {
  type: typeof SET_CURRENT_USER
  payload: CognitoUserInterface
}

interface UnsetCurrentUser {
  type: typeof UNSET_CURRENT_USER
}
interface SetAuthState {
  type: typeof SET_AUTH_STATE
  payload: AuthState | undefined
}

interface UnsetAuthState {
  type: typeof UNSET_AUTH_STATE
}

export type UserTypes =
  | SetCurrentUser
  | UnsetCurrentUser
  | SetAuthState
  | UnsetAuthState

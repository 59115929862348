import * as Yup from 'yup'
import { isValidPhoneNumber } from '@app/utils/validators/phoneNumber'

export const yupPhoneNumber = (
  errorMessage = 'Please enter a valid phone number'
) =>
  Yup.string().test('is-valid-phone', errorMessage, (value) => {
    if (!value) {
      return true
    }
    return isValidPhoneNumber(value)
  })

export const yupRequiredBoolean = (errorMessage?: string) =>
  Yup.bool()
    .required()
    .oneOf([true], errorMessage || 'This field is required')

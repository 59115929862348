import {
  IncomingDataParams,
  IntegrationCredentialEditParams,
  IntegrationCredentialParams,
} from 'LeadsBridgeApp'

const integrationPath = 'integration'

export default {
  integration: {
    path: `/${integrationPath}`,
    makeUrl: ({ appId }: IntegrationCredentialParams) =>
      `/${integrationPath}/credentials/${appId}/new`,
  },
  integrationAuthScreen: {
    path: `/${integrationPath}/credentials/:appId/new`,
    makeUrl: ({ appId }: IntegrationCredentialParams) =>
      `/${integrationPath}/credentials/${appId}/new`,
  },
  integrationAuthScreenEdit: {
    path: `/${integrationPath}/credentials/:appId/edit/:integrationId`,
    makeUrl: ({ integrationId, appId }: IntegrationCredentialEditParams) =>
      `/${integrationPath}/credentials/${appId}/edit/${integrationId}`,
  },
  integrationAuthScreenEditWithBridgeId: {
    path: `/${integrationPath}/credentials/:appId/edit/:integrationId/bridge/:bridgeId`,
    makeUrl: ({
      integrationId,
      appId,
      bridgeId,
    }: IntegrationCredentialEditParams) =>
      `/${integrationPath}/credentials/${appId}/edit/${integrationId}/bridge/${bridgeId}`,
  },
  integrationAuthScreenEditStep2: {
    path: `/${integrationPath}/credentials/:appId/edit/:integrationId/save`,
    makeUrl: ({ integrationId, appId }: IntegrationCredentialEditParams) =>
      `/${integrationPath}/credentials/${appId}/edit/${integrationId}/save`,
  },
  integrationAuthScreenCallback: {
    path: `/${integrationPath}/oauth/callback`,
    makeUrl: () => `/${integrationPath}/oauth/callback`,
  },

  // NEW OAUTH ROUTE
  integrationAuthScreenCallback2: {
    path: `/${integrationPath}/oauth2/callback`,
    makeUrl: () => `/${integrationPath}/oauth2/callback`,
  },

  // Incoming data routes
  incomingData: {
    path: `/${integrationPath}/incoming-data`,
    makeUrl: () => `/${integrationPath}/incoming-data`,
  },

  incomingDataScreen: {
    path: `/${integrationPath}/incoming-data/:appId/new/:integrationId`,
    makeUrl: ({ appId, integrationId }: IncomingDataParams) =>
      `/${integrationPath}/incoming-data/${appId}/new/${integrationId}`,
  },
  incomingDataScreenEdit: {
    path: `/${integrationPath}/incoming-data/:appId/edit/:integrationId`,
    makeUrl: ({ appId, integrationId }: IncomingDataParams) =>
      `/${integrationPath}/incoming-data/${appId}/edit/${integrationId}`,
  },
  incomingDataScreenFields: {
    path: `/${integrationPath}/incoming-data/:appId/fields/:integrationId?`,
    makeUrl: ({ appId, integrationId }: IncomingDataParams) =>
      `/${integrationPath}/incoming-data/${appId}/fields/${integrationId}?`,
  },

  incomingDataScreenSave: {
    path: `/${integrationPath}/incoming-data/:appId/save/:integrationId?`,
    makeUrl: ({ appId, integrationId }: IncomingDataParams) =>
      `/${integrationPath}/incoming-data/${appId}/save/${integrationId}`,
  },
}

import { FbAppStoreStep } from 'LeadsBridgeApp'
import { Image, Step } from 'semantic-ui-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../index'

interface FbStepGroupProsp {
  isLoading: boolean
  status: any
  steps: any
}
const FbStepGroup: React.FC<FbStepGroupProsp> = ({
  isLoading,
  steps,
  status,
}) => {
  const platformId: number = useSelector(
    (state: RootState) => state.appStore.platformId
  )
  return (
    <>
      {!isLoading && (
        <Step.Group size='mini' fluid>
          {steps.length > 0 &&
            steps.map((step: FbAppStoreStep) => {
              const included = step.values.includes(status)
              const exists = steps.find((s: FbAppStoreStep) =>
                step.values.includes(status)
              )
              return (
                <Step
                  active={included}
                  key={'stepper-' + step.index}
                  disabled={exists ? step.index < exists.index : true}
                >
                  <Step.Content>
                    <Step.Title>
                      {step.index === 0 ? (
                        <Image
                          src={`https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo${platformId}.png`}
                          style={{ borderRadius: 0 }}
                          avatar
                        />
                      ) : (
                        <></>
                      )}
                      {step.index === steps.length - 1 ? (
                        <Image
                          src={`https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo371.png`}
                          style={{ borderRadius: 0 }}
                          avatar
                        />
                      ) : (
                        <></>
                      )}
                      {step.name}
                    </Step.Title>
                  </Step.Content>
                </Step>
              )
            })}
        </Step.Group>
      )}
    </>
  )
}

export default FbStepGroup

import { RouteHandler } from 'miragejs/server'
import { AnyFactories, AnyModels, Registry } from 'miragejs/-types'
import { Response } from 'miragejs'

export const postChooseIntegrationSettings: RouteHandler<
  Registry<AnyModels, AnyFactories>
> = (schema, request) => {
  const payload = {
    data: {
      schemaSettings: [
        {
          id: 'segmentId',
          type: 'google_picker',
          label: 'Google Spreadsheet',
          order: 1,
          default: null,
          required: true,
          data: [
            {
              id: '17l4EILlMz64mPkbOk8Qni64Y2uVxOVS6o5pJlyJ8zc8',
              text: 'June - LB Kw research 2024',
            },
            {
              id: 'accessToken',
              text: 'ya29.a0AXooCguvjKmab8Fe40IOaLVjtJYkglqN4lUyWHOOgswZFTrR_Qejj4XOALW-xTy0BCc100VViyF5fBaC-u0Gg9799Vw_mCQoWFAU8PrZEE8ZVV1-LIp6RwxpU9Jgt9LSrzIQfU71CFBJ-gPhR6-ILAxMU_A8NdYJFEMoCAaCgYKAZcSARASFQHGX2MieTMO6TzsgakUT0llNGgoiA0173',
            },
          ],
          refreshable: true,
        },
        {
          id: 'listId',
          hide: true,
          type: 'text',
          label: 'OR Google Spreadsheet Id',
          order: 2,
          docUrl:
            'https://community.activeprospect.com/posts/5090366-how-leadsbridge-counts-the-custom-audiences-contacts',
          default: '',
          tooltip:
            'This value is optional and you could specify the sheet in the Bridge configuration but if you have more than 500 sheets in your Google Account, it is important to specify here otherwise leave it blank.',
          hasChild: true,
          required: false,
          refreshable: false,
        },
        {
          id: 'moreSettings',
          type: 'toggle',
          label: 'More Settings',
          order: 4,
          default: 0,
          tooltip: '',
          hasChild: true,
          required: false,
          refreshable: false,
        },
      ],
    },
  }

  return new Response(200, {}, payload)
}

export default {
  fbe: {
    path: `/fbe`,
    makeUrl: () => `/fbe`,
  },
  fbeCallback: {
    path: `/fbe/callback`,
    makeUrl: () => `/fbe/callback`,
  },
  fbAppStore: {
    path: `/fbAppStore`,
    makeUrl: () => `/fbAppStore`,
  },
  fbAppStoreDone: {
    path: `/fbAppStore/done`,
    makeUrl: () => `/fbAppStore/done`,
  },
}

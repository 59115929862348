import { createReducer } from '@reduxjs/toolkit'
import {
  IntegrationListenerState,
  UNSET_INTEGRATION_LISTENER,
} from '../types/IntegrationListenerTypes'

const initialState: IntegrationListenerState = {
  data: null,
}
export const IntegrationListenerReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        'SET_INTEGRATION_LISTENER',
        (state: IntegrationListenerState, action: any) => {
          state.data = action.payload
        }
      )
      .addCase(
        'SET_INTEGRATION_LISTENER_FIELDS',
        (state: IntegrationListenerState, action: any) => {
          if (state.data && action.payload.data) {
            state.data.fields = [...action.payload.data.recognizedFields]
          }
        }
      )
      .addCase(
        'SET_INTEGRATION_LISTENER_STATUS',
        (state: IntegrationListenerState, action: any) => {
          if (state.data) {
            state.data.status = action.payload
          }
        }
      )
      .addCase(UNSET_INTEGRATION_LISTENER, () => {
        return initialState
      })
  }
)

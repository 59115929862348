import { CognitoErrorResponse } from 'LeadsBridgeApp'
import t from 'public/locales/en-US.json'

type CognitoErrorCodeI18n = keyof typeof t.cognitoErrors

// Please keep it synched with -> https://leadsbridge.atlassian.net/wiki/spaces/LH/pages/885030923/Custom+mapping+of+Cognito+Responses
export const getCustomErrorCognitoMessage = (res: CognitoErrorResponse) => {
  return (
    t.cognitoErrors[res.code as CognitoErrorCodeI18n] ||
    res.message ||
    'Something is wrong, this is an unexpected error'
  )
}

import { createReducer } from '@reduxjs/toolkit'
import {
  ApplicationConfigurationState,
  SEND_TOAST,
  SEND_ALERT_MESSAGE,
  TOGGLE_SIDEBAR,
  SET_LOGIN_LAYOUT,
  CLOSE_ALERT_MESSAGE,
  SET_PATH,
  RESET_PATH,
  TRIGGER_CONNECT_ACCOUNT_POPUP,
  RESET_TRIGGER_CONNECT_ACCOUNT_POPUP,
  SET_TRACK_SIGNIN,
} from '../types/ApplicationConfigurationTypes'

const initialState: ApplicationConfigurationState = {
  displayVerticalSidebar: false,
  toast: null,
  alert: null,
  user: undefined,
  loginLayout: null,
  path: null,
  trackSignIn: false,
  integrationId: null,
}

export const ApplicationConfigurationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        TOGGLE_SIDEBAR,
        (state: ApplicationConfigurationState, action: any) => {
          state.displayVerticalSidebar = !state.displayVerticalSidebar
        }
      )
      .addCase(
        SEND_TOAST,
        (state: ApplicationConfigurationState, action: any) => {
          state.toast = action.payload
        }
      )
      .addCase(
        SEND_ALERT_MESSAGE,
        (state: ApplicationConfigurationState, action: any) => {
          state.alert = action.payload
        }
      )
      .addCase(
        CLOSE_ALERT_MESSAGE,
        (state: ApplicationConfigurationState, action: any) => {
          if (state.alert && !state.alert.persistent) {
            state.alert = null
          }
        }
      )
      .addCase(
        SET_LOGIN_LAYOUT,
        (state: ApplicationConfigurationState, action: any) => {
          state.loginLayout = action.payload
        }
      )
      .addCase(
        SET_PATH,
        (state: ApplicationConfigurationState, action: any) => {
          state.path = action.payload
        }
      )
      .addCase(
        RESET_PATH,
        (state: ApplicationConfigurationState, action: any) => {
          state.path = null
        }
      )
      .addCase(
        SET_TRACK_SIGNIN,
        (state: ApplicationConfigurationState, action: any) => {
          state.trackSignIn = action.payload
        }
      )
      .addCase(
        TRIGGER_CONNECT_ACCOUNT_POPUP,
        (state: ApplicationConfigurationState, action: any) => {
          state.integrationId = action.payload
        }
      )
      .addCase(
        RESET_TRIGGER_CONNECT_ACCOUNT_POPUP,
        (state: ApplicationConfigurationState, action: any) => {
          state.integrationId = null
        }
      )
  }
)

// generates random id;
export const generateRandomId = () => {
  const labelId = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    // test deploy
    labelId() +
    labelId() +
    '-' +
    labelId() +
    '-' +
    labelId() +
    '-' +
    labelId() +
    '-' +
    labelId() +
    labelId() +
    labelId()
  )
}

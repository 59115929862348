import { css, createGlobalStyle } from 'styled-components'

export const sizing = [0, 1, 2, 3, 4, 5]

export const ClassNameUtils = createGlobalStyle`
  .fitContent{
    max-width: fit-content;
    max-width: max-content;
  }

  .text-center{
    text-align: center;
  }

  ${sizing.map(
    (index) => css`

      .m-${index} {
        margin: ${index}rem;
      }
      
      .mt-${index} {
        margin-top: ${index}rem;
      }

      .mb-${index} {
        margin-bottom: ${index}rem;
      }

      .ml-${index} {
        margin-left: ${index}rem;
      }

      .mr-${index} {
        margin-right: ${index}rem;
      }

      .my-${index} {
        margin-top: ${index}rem;
        margin-bottom: ${index}rem;
      }

      .mx-${index} {
        margin-left: ${index}rem;
        margin-right: ${index}rem;
      }
      
      // Padding
      
      .p-${index} {
        padding: : ${index}rem;
      }
      
      .pt-${index} {
        padding-top: ${index}rem;
      }

      .pb-${index} {
        padding-bottom: ${index}rem;
      }

      .pl-${index} {
        padding-left: ${index}rem;
      }

      .pr-${index} {
        padding-right: ${index}rem;
      }

      .py-${index} {
        padding-top: ${index}rem;
        padding-bottom: ${index}rem;
      }

      .px-${index} {
        padding-left: ${index}rem;
        padding-right: ${index}rem;
      }
    `
  )}
  
  
  .divisor-label{
    position: relative;
  }
  .divisor-label > * {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    padding: 0 10px;
    background-color: white;
  }

  /* do we need this? */
  .list {
    &.unlisted{
      list-style: none;
      padding-left: 0 ;
    }
   
    &.list-even li:nth-child(even),
    &.list-odd li:nth-child(odd) {
      background-color: whitesmoke
    }
  }
  
  [autocomplete="off"]:-webkit-autofill {
    display: none!important;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  
  .reactTooltipSmall {
    max-width: 40rem;
  }

  .reactTooltipFilterIcons {
    padding: 0 1rem !important;
    font-size: .7rem !important;
  }

  .customTooltipIcon {
    width: 15rem;
    box-shadow: 0px 4px 10px 0px #808080;
    border-radius: 5px;
  }

  .customTooltipInput {
    max-width: 70%;
    box-shadow: 0px 4px 10px 0px #808080;
    border-radius: 5px;
    word-break: break-all;
  }
  
`

import {
  FbAppStoreTypes,
  SET_PLATFORM_ID,
  SET_STATUS,
} from '../types/FbAppStoreTypes'
import { AppStoreStatus } from '../../enums/AppStoreStatus'

export const setStatusAction = (status: AppStoreStatus): FbAppStoreTypes => {
  return {
    type: SET_STATUS,
    payload: status,
  }
}

export const setPlatformIdAction = (platformId: number): FbAppStoreTypes => {
  return {
    type: SET_PLATFORM_ID,
    payload: platformId,
  }
}

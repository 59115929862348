import React, {
  createRef,
  Dispatch,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import {
  Button,
  Form,
  Header,
  Icon,
  Message,
  Search,
  SearchProps,
  SearchResultData,
  Segment,
} from 'semantic-ui-react'
import { filter, debounce, escapeRegExp } from 'lodash-es'
import { FbAppStoreStepper } from '../../../utils/appStore/FbAppStoreStepper'
import { FbAppStoreStep } from 'LeadsBridgeApp'
import FbAppStoreService from '../../../services/FbAppStoreService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import ReactDOM from 'react-dom'
import { sendToast } from '../../../store/actions/ApplicationConfigurationActions'
import styled from 'styled-components'

const SearchStyled = styled.div`
  display: flex;
  width: 100%;
  .search {
    width: 100%;
  }
  &#document-search .results {
    // use searchResultHeight
    max-height: 210px;
    overflow-y: auto;
  }
  &#worksheet-search .results {
    max-height: 150px;
    overflow-y: auto;
  }
`

const SpreadSheetSelection = forwardRef(
  (props: { steps: FbAppStoreStep[] }, ref) => {
    const dispatch: Dispatch<any> = useDispatch()

    const platformId: number = useSelector(
      (state: RootState) => state.appStore.platformId
    )
    const [formError, setFormError] = useState<string>('')

    const [sheetTemplateUrl, setSheetTemplateUrl] = useState<string>('')
    const [documentationTemplateUrl, setDocumentationTemplateUrl] =
      useState<string>('')

    const [isFilteringSheets, setIsFilteringSheets] = useState<boolean>(true)
    const [sheet, setSheet] = useState<string>('')
    const [sheetId, setSheetId] = useState<string>('')

    const [results, setResults] = useState<any[]>([])

    const [isFilteringWorksheets, setIsFilteringWorksheets] =
      useState<boolean>(false)
    const [worksheet, setWorksheet] = useState<string>('')

    const searchRef = createRef<any>()
    const [worksheetId, setWorksheetId] = useState<string>('')
    const [workResults, setWorkResults] = useState<any[]>([])

    const search2Ref = createRef<any>()
    const [sheets, setSheets] = useState<{ title: string; value: string }[]>([])
    const [worksheets, setWorksheets] = useState<
      { title: string; value: string }[]
    >([])

    const [refreshingList, setRefreshingList] = useState<boolean>(false)
    const [type, setType] = useState<string>('')

    const [nextStep, setNextStep] = useState<boolean>(false)

    const handleSearchChange = (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      data: SearchProps
    ) => {
      setIsFilteringSheets(true)
      const dataValue = data.value!.toString()
      setSheet(dataValue)
      setTimeout(() => {
        const re = new RegExp(escapeRegExp(data.value), 'i')
        const isMatch = (result: { title: string; value: string }) =>
          re.test(result.title)
        setIsFilteringSheets(false)
        setResults(filter(sheets, isMatch))
      }, 300)
    }
    const handleResultSelect = (e: any, value: SearchResultData) => {
      const result: { title: string; value: string } = value.result
      setWorksheet('')
      setSheet(result.title)
      setSheetId(result.value)
    }

    const handleSearchChangeWork = (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      data: SearchProps
    ) => {
      setIsFilteringWorksheets(true)
      const dataValue = data.value!.toString()
      setWorksheet(dataValue)
      setTimeout(() => {
        const re = new RegExp(escapeRegExp(data.value), 'i')
        const isMatch = (result: { title: string; value: string }) =>
          re.test(result.title)
        setIsFilteringWorksheets(false)
        const filteredResults = filter(worksheets, isMatch)
        setWorkResults(filteredResults)
      }, 300)
    }
    const handleResultSelectWork = (e: any, value: SearchResultData) => {
      const result: { title: string; value: string } = value.result
      setWorksheet(result.title)
      setWorksheetId(result.value)
    }

    const refreshTheList = () => {
      setRefreshingList(true)
      // backend api
      setTimeout(() => setRefreshingList(false), 1000)
    }

    useEffect(() => {
      if (sheetId !== '' && type !== '') {
        clickListener()
        setIsFilteringWorksheets(true)
        if (type === 'google') {
          FbAppStoreService.getSpreadSheetTabs(platformId, sheetId)
            .then((res) => {
              //
              setWorksheets(res.Tabs)
            })
            .catch((e) => {
              const error =
                e.response?.data?.errorMessage ??
                'An internal error occurred, please try again later.'
              dispatch(
                sendToast({
                  title: 'An error occurred',
                  messages: [error],
                  color: 'negative',
                })
              )
            })
            .finally(() => {
              setIsFilteringWorksheets(false)
            })
        } else {
          FbAppStoreService.getSegments(platformId, sheetId)
            .then((res) => {
              setWorksheets(res.segments)
            })
            .catch((e) => {
              const error =
                e.response?.data?.errorMessage ??
                'An internal error occurred, please try again later.'
              dispatch(
                sendToast({
                  title: 'An error occurred',
                  messages: [error],
                  color: 'negative',
                })
              )
            })
            .finally(() => {
              setIsFilteringWorksheets(false)
            })
        }
      }
    }, [sheetId, type])

    useEffect(() => {
      if (worksheetId !== '') {
        clickListener()
      }
    }, [worksheetId])

    const next = () => {
      if (worksheetId === '' || sheet === '') {
        setFormError('All fields are required')
        return
      }
      setNextStep(true)
      if (type === 'google') {
        FbAppStoreService.saveSheetSelection(platformId, sheetId, worksheetId)
          .then(() => {})
          .catch((e) => {
            const error =
              e.response?.data?.errorMessage ??
              'An internal error occurred, please try again later.'
            dispatch(
              sendToast({
                title: 'An error occurred',
                messages: [],
                color: 'negative',
              })
            )
          })
          .finally(() => {
            setNextStep(false)
          })
      } else {
        FbAppStoreService.saveSegmentSelection(platformId, sheetId, worksheetId)
          .then(() => {})
          .catch((e) => {
            const error =
              e.response?.data?.errorMessage ??
              'An internal error occurred, please try again later.'
            dispatch(
              sendToast({
                title: 'An error occurred',
                messages: [],
                color: 'negative',
              })
            )
          })
          .finally(() => {
            setNextStep(false)
          })
      }
    }

    let onClickEVent: any = null

    const clickListener = () => {
      const res = document.getElementsByClassName('results')
      for (let k = 0; k < res.length; k++) {
        // @ts-ignore
        res[k].style.display = 'none'
      }
      document.removeEventListener('click', clickListener)
      onClickEVent = null
    }

    const eventClick = (e: any) => {
      clickListener()
      setFormError('')
      if (sheet === '' && !isFilteringSheets) {
        const element = ReactDOM.findDOMNode(searchRef.current) as Element
        const res = element?.getElementsByClassName('results')
        for (let k = 0; k < res.length; k++) {
          // @ts-ignore
          res[k].style.display = 'block'
        }
        setResults(sheets)
        if (!onClickEVent) {
          onClickEVent = document.addEventListener('click', clickListener)
        }
      }
    }
    const eventClickSearch2 = (e: any) => {
      clickListener()
      setFormError('')
      if (worksheet === '' && !isFilteringWorksheets) {
        const element = ReactDOM.findDOMNode(search2Ref.current) as Element
        const res = element?.getElementsByClassName('results')
        for (let k = 0; k < res.length; k++) {
          // @ts-ignore
          res[k].style.display = 'block'
        }
        setWorkResults(worksheets)

        if (!onClickEVent) {
          onClickEVent = document.addEventListener('click', clickListener)
        }
      }
    }

    useEffect(() => {
      if (platformId > 0 && type !== '') {
        if (type === 'google') {
          FbAppStoreService.getSheets(platformId)
            .then((res: any) => {
              setSheetTemplateUrl(res.sheetTemplateUrl)
              setDocumentationTemplateUrl(res.documentationTemplateUrl)
              const shits = []
              for (let k = 0; k < res.sheets.length; k++) {
                const c = { ...res.sheets[k] }
                c.key = c.sheetId
                shits.push(c)
              }
              setSheets(shits)
            })
            .finally(() => {
              setIsFilteringSheets(false)
            })
        } else {
          FbAppStoreService.segmentTypes(platformId)
            .then((res: any) => {
              setSheets(res.segmentTypes)
            })
            .finally(() => {
              setIsFilteringSheets(false)
            })
        }
      }
    }, [platformId, type])

    useEffect(() => {
      if (FbAppStoreStepper.newType === props.steps) {
        setType('newType')
      } else {
        setType('google')
      }
    }, [])

    return (
      <>
        {type === 'google' && (
          <Segment>
            <Header as={'h4'}>STEP 1</Header>
            <p>
              In order to enable our mapping configuration, please use our
              google sheet template and place it in your Google Drive.
            </p>
            <p>Download our template and follow the instructions.</p>
            <div style={{ display: 'flex' }}>
              <Button
                icon
                labelPosition='left'
                onClick={() => {
                  window.open(sheetTemplateUrl, '_blank')
                }}
              >
                <Icon name='download' /> Download the template
              </Button>
              <Button
                icon
                labelPosition='left'
                onClick={() => {
                  window.open(documentationTemplateUrl, '_blank')
                }}
              >
                <Icon name='download' /> How to configure the document
              </Button>
            </div>
          </Segment>
        )}
        <Segment>
          <Header as={'h4'}>STEP {type === 'google' ? 2 : 1}</Header>
          <p>
            Select the {type === 'google' ? 'document' : 'segment'} where you
            have the data to sync.
          </p>

          <SearchStyled id={'document-search'}>
            <Search
              style={{ flex: 1, marginRight: '15px' }}
              fluid
              loading={isFilteringSheets}
              onResultSelect={handleResultSelect}
              onSearchChange={debounce(handleSearchChange, 500, {
                leading: true,
              })}
              onClick={(e: any) => {
                eventClick(e)
              }}
              results={results}
              placeholder={
                type === 'google' ? 'Select Sheet' : 'Select Segment Type'
              }
              value={sheet}
              ref={searchRef}
            />
            <Button
              disabled={refreshingList}
              onClick={refreshTheList}
              icon={'sync'}
              title={'Refresh the list'}
              className={refreshingList ? 'loading' : ''}
            />
          </SearchStyled>

          <SearchStyled id={'worksheet-search'}>
            <Search
              disabled={sheet === ''}
              style={{ marginTop: '13px' }}
              fluid
              placeholder={
                isFilteringSheets
                  ? 'Loading'
                  : type === 'google'
                  ? 'Select Worksheet'
                  : 'Select Segment'
              }
              loading={isFilteringWorksheets}
              onResultSelect={handleResultSelectWork}
              onClick={(e: any) => {
                eventClickSearch2(e)
              }}
              onSearchChange={debounce(handleSearchChangeWork, 500, {
                leading: true,
              })}
              results={workResults}
              value={worksheet}
              ref={search2Ref}
            />
          </SearchStyled>
        </Segment>

        <Segment>
          <Header as={'h4'}>STEP {type === 'google' ? 3 : 2}</Header>
          <Form error={formError !== ''}>
            <Message error>{formError}</Message>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ margin: 0 }}>
                All done. Click on the 'Next' button to start mapping.
              </p>

              <Button
                color={'blue'}
                disabled={nextStep}
                className={nextStep ? 'loading disabled' : ''}
                onClick={() => next()}
              >
                Next
              </Button>
            </div>
          </Form>
        </Segment>
      </>
    )
  }
)

SpreadSheetSelection.displayName = 'SpreadSheetSelection'

export default SpreadSheetSelection

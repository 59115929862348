import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'
import {
  SET_AUTH_STATE,
  UNSET_AUTH_STATE,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
  UserTypes,
} from '../types/UserTypes'

export const setCurrentUserAction = (user: CognitoUserInterface): UserTypes => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  }
}

export const unsetCurrentUserAction = (): UserTypes => {
  return {
    type: UNSET_CURRENT_USER,
  }
}

export const setAuthStateAction = (authState: AuthState): UserTypes => {
  return {
    type: SET_AUTH_STATE,
    payload: authState,
  }
}

export const unsetAuthStateAction = (): UserTypes => {
  return {
    type: UNSET_AUTH_STATE,
  }
}

const adminPath = 'admin-private'

export default {
  adminPrivateRoot: {
    path: `/${adminPath}`,
    makeUrl: () => `/${adminPath}`,
  },
  adminPrivateValidateFbToken: {
    path: `/${adminPath}/validate-facebook-token`,
    makeUrl: () => `/${adminPath}/validate-facebook-token`,
  },
}

const arrayItemSeparator = ','

export const encodeToBase64 = (value: string | number | boolean | string[]) => {
  const valueToEncode = Array.isArray(value)
    ? value.join(arrayItemSeparator)
    : value
  return window.btoa(unescape(encodeURIComponent(valueToEncode)))
}

// if original encoded value was an array, we pass `parseArray` as tru
export const decodeFromBase64 = (
  encodedValue: string,
  parseArray?: boolean
) => {
  const decodedValue = decodeURIComponent(escape(window.atob(encodedValue)))
  if (parseArray) {
    // handling array and prevent to not create [''] from []
    return decodedValue === '' ? [] : decodedValue.split(arrayItemSeparator)
  }
  return decodedValue
}

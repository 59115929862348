import { createReducer } from '@reduxjs/toolkit'
import {
  SET_AUTH_STATE,
  SET_CURRENT_USER,
  UNSET_AUTH_STATE,
  UNSET_CURRENT_USER,
  UserState,
} from '../types/UserTypes'
import { AuthState } from '@aws-amplify/ui-components'

const initialState: UserState = {
  auth: undefined,
  authState: undefined,
}

export const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_CURRENT_USER, (state: UserState, action: any) => {
      state.auth = action.payload
    })
    .addCase(UNSET_CURRENT_USER, (state: UserState) => {
      state.auth = undefined
    })
    .addCase(SET_AUTH_STATE, (state: UserState, action: any) => {
      state.authState = action.payload
    })
    .addCase(UNSET_AUTH_STATE, (state: UserState) => {
      state.authState = AuthState.SignIn
    })
})

import {
  ApplicationConfigurationTypes,
  CLOSE_ALERT_MESSAGE,
  RESET_PATH,
  SEND_ALERT_MESSAGE,
  SEND_TOAST,
  SET_LOGIN_LAYOUT,
  TRIGGER_CONNECT_ACCOUNT_POPUP,
  RESET_TRIGGER_CONNECT_ACCOUNT_POPUP,
  SET_TRACK_SIGNIN,
  SET_PATH,
  TOGGLE_SIDEBAR,
} from '../types/ApplicationConfigurationTypes'
import { Alert, Toast } from 'LeadsBridgeApp'

export const toggleVerticalSidebar = (): ApplicationConfigurationTypes => {
  return {
    type: TOGGLE_SIDEBAR,
  }
}
export function sendToast(toast: Toast): ApplicationConfigurationTypes {
  return {
    type: SEND_TOAST,
    payload: toast,
  }
}
export function sendAlertMessage(
  alertMessage: Alert
): ApplicationConfigurationTypes {
  return {
    type: SEND_ALERT_MESSAGE,
    payload: alertMessage,
  }
}

export const closeAlertMessage = (): ApplicationConfigurationTypes => {
  return {
    type: CLOSE_ALERT_MESSAGE,
  }
}

export const setLoginLayout = (
  source: string
): ApplicationConfigurationTypes => {
  return {
    type: SET_LOGIN_LAYOUT,
    payload: source,
  }
}

export const setTrackSignIn = (
  state: boolean
): ApplicationConfigurationTypes => {
  return {
    type: SET_TRACK_SIGNIN,
    payload: state,
  }
}

export const triggerConnectAccountPopup = (
  integrationId: number
): ApplicationConfigurationTypes => {
  return {
    type: TRIGGER_CONNECT_ACCOUNT_POPUP,
    payload: integrationId,
  }
}

export const resetTriggerConnectAccountPopup =
  (): ApplicationConfigurationTypes => {
    return {
      type: RESET_TRIGGER_CONNECT_ACCOUNT_POPUP,
    }
  }

export const setPath = (path: string): ApplicationConfigurationTypes => {
  return {
    type: SET_PATH,
    payload: path,
  }
}

export const resetPath = (): ApplicationConfigurationTypes => {
  return {
    type: RESET_PATH,
  }
}

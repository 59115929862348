import { AppStoreStatus } from '../../enums/AppStoreStatus'

export const FbAppStoreStepper = {
  googleSheet: [
    {
      index: 0,
      values: [AppStoreStatus.PLATFORMAUTHENTICATION, AppStoreStatus.NONE],
      name: 'Connect',
    },
    {
      index: 1,
      values: [AppStoreStatus.SPREADSHEETSELECTION],
      name: 'Select document',
    },
    {
      index: 2,
      values: [AppStoreStatus.PLATFORMCONFIGURATION],
      name: 'Review & confirm',
    },
    {
      index: 3,
      values: [AppStoreStatus.PUBLISH],
      name: 'Sync data',
    },
  ],
  newType: [
    {
      index: 0,
      values: [AppStoreStatus.PLATFORMAUTHENTICATION, AppStoreStatus.NONE],
      name: 'Connect',
    },
    {
      index: 1,
      values: [AppStoreStatus.STATUS_SEGMENTATION_SELECTION],
      name: 'Select document',
    },
    {
      index: 2,
      values: [AppStoreStatus.PLATFORMCONFIGURATION],
      name: 'Review & confirm',
    },
    {
      index: 3,
      values: [AppStoreStatus.PUBLISH],
      name: 'Sync data',
    },
  ],
  default: [
    {
      index: 0,
      values: [AppStoreStatus.PLATFORMAUTHENTICATION, AppStoreStatus.NONE],
      name: 'Connect',
    },
    {
      index: 1,
      values: [AppStoreStatus.PLATFORMCONFIGURATION],
      name: 'Review & confirm',
    },
    {
      index: 2,
      values: [AppStoreStatus.PUBLISH],
      name: 'Sync data',
    },
  ],
}

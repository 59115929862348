import { ApiBridgeFieldDefinitionType } from 'BackendApi'

export const apiBridgeFieldTypes: ApiBridgeFieldDefinitionType[] = [
  'text',
  'dropdown',
  'number',
  'date',
  'time',
  'datetime',
  'boolean',
]

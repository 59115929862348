import { createGlobalStyle } from 'styled-components'

// !important everywhere!!! We know and we'll get rid of semantic at some point.
// Until then, this is the only way

export const SemanticOverwrites = createGlobalStyle`
  html, body {
    font-family: 'Open Sans', sans-serif !important;
  }
  .ui.input.error > input {
    background-color: #FEF5F4 !important;
    border-color: #CC2029 !important;
    color: #9f3a38 !important;
    box-shadow: none !important;
  }
  
  .ui.bulleted.list {
    margin-left: 13px !important;
  }
  
  .ui.bulleted.list .item::before {
    color: #A1A4B1 !important;
    font-size: 22px;
    line-height: 0.36;
    margin-left: -13px !important;
  }

  .ui.form .field .prompt.label{
    padding-right: 0;
    padding-left: 0;
    border-color: white !important;
    margin-top: 0;
  }
  .ui.form .field .prompt.label:before{
    content: unset;
  }
`

import { AppStoreStatus } from '../../enums/AppStoreStatus'

export const SET_STATUS = 'SET_STATUS'
export const SET_PLATFORM_ID = 'SET_PLATFORM_ID'

export interface FbAppStoreState {
  status: AppStoreStatus
  platformId: number
}

interface SetStatus {
  type: typeof SET_STATUS
  payload: AppStoreStatus
}

interface SetPlatformId {
  type: typeof SET_PLATFORM_ID
  payload: number
}

export type FbAppStoreTypes = SetStatus | SetPlatformId

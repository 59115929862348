import React, { Dispatch, useEffect, useState } from 'react'
import * as QueryString from 'querystring'
import { AppStoreStatus } from '../../enums/AppStoreStatus'
import PlatformAuthentication from './components/PlatformAuthentication'
import PlatformConfiguration from './components/PlatformConfiguration'
import SpreadSheetSelection from './components/SpreadSheetSelection'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../index'
import {
  setPlatformIdAction,
  setStatusAction,
} from '../../store/actions/FbAppStoreActions'
import { doPostMessage, Messages } from '../../utils/PostMessages'
import {
  Button,
  Container,
  Dimmer,
  Icon,
  List,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react'
import { FbAppStoreStepper } from '../../utils/appStore/FbAppStoreStepper'
import {
  FbAppStoreStep,
  GraphQLResult,
  FacebookParameters,
} from 'LeadsBridgeApp'
import { onUpdateFbAppStoreStatus } from '../../graphql/subscriptions'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { listFbAppStoreStatuss } from '../../graphql/queries'

import { Observable } from 'zen-observable-ts'
import SyncData from './components/SyncData'
import LoginV1Service from '../../services/ApplicationV1Bridge'

import { createFbAppStoreStatus } from '../../graphql/mutations'
import { ParsedUrlQuery } from 'querystring'
import { LangFbAppStore } from '../../lang/LangFbAppStore'
import FbTopBar from './partials/FbTopBar'
import FbStepGroup from './partials/FbStepGroup'

const FbAppStore: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [APIresponse, setAPIresponse] = useState<any>({})

  const platformId: number = useSelector(
    (state: RootState) => state.appStore.platformId
  )

  const setPlatformId = React.useCallback(
    (platformId: number) => dispatch(setPlatformIdAction(platformId)),
    [dispatch]
  )
  // test
  const status: AppStoreStatus = useSelector(
    (state: RootState) => state.appStore.status
  )
  const setStatus = React.useCallback(
    (status: AppStoreStatus) => dispatch(setStatusAction(status)),
    [dispatch]
  )
  const [steps, setSteps] = useState<FbAppStoreStep[]>([])

  const doFacebookRedirect = (redirectUrl: string) => {
    LoginV1Service.facebookParameters(
      'APP_STORE',
      '',
      window.location.href
    ).then((res: FacebookParameters) => {
      const urlObject = new URL(redirectUrl)
      let qs: string = urlObject.search.slice(1)
      const qsObject: ParsedUrlQuery = QueryString.parse(qs)
      qsObject.extras = JSON.stringify(res.extras)
      qs = QueryString.encode(qsObject)
      const url: string =
        urlObject.href.replace(urlObject.search, '') + '?' + qs
      window.location.replace(url)
    })
  }

  useEffect(() => {
    // alert(platformId);

    // import { hotjar } from "react-hotjar";
    // TODO hotjar.initialize(2000196, 5);

    let subscribed: boolean = true
    const params = QueryString.parse(window.location.search.replace('?', ''))
    const redirectUrl: string = params.redirect_uri as string
    if (redirectUrl) {
      doPostMessage(Messages.AppInvoked)
      doPostMessage(Messages.UserLogged)
      doFacebookRedirect(redirectUrl)
    } else {
      setIsLoading(false)
    }
    if (params.crm_id && parseInt(params.crm_id as string) > 0) {
      setPlatformId(parseInt(params.crm_id as string))
    }
    if (params.crm_id && parseInt(params.crm_id as string) === 63) {
      setSteps(FbAppStoreStepper.googleSheet)
    } else if (params.crm_id && parseInt(params.crm_id as string) === 85) {
      setSteps(FbAppStoreStepper.newType)
    } else {
      setSteps(FbAppStoreStepper.default)
    }

    // TODO
    // me lo passava un response.data.alreadyPublished di backend
    // 'You already connected this platform with Facebook through LeadsBridge. Click Continue to proceed with an additional integration or Exit to restart with Facebook.',

    // modale popup       {action: 'redirect', url: 'https://business.facebook.com/business/app-store', text: 'Exit'} oppure continua

    return () => {
      subscribed = false
    }
  }, [])

  const retrieveStatusAndSubscribe = async (
    username: string
  ): Promise<ZenObservable.Subscription> => {
    const subClient = (await API.graphql(
      graphqlOperation(onUpdateFbAppStoreStatus, {
        owner: username,
      })
    )) as Observable<object>

    return subClient.subscribe({
      next: (data: any) => {
        if (
          data.value &&
          data.value.data &&
          data.value.data.onUpdateFbAppStoreStatus
        ) {
          const item = data.value.data.onUpdateFbAppStoreStatus
          setStatus(item.status)
        }
      },
      error: (error: any) => console.warn('subscription c1 error: ', error),
    })
  }

  // [{userId: user.username}]
  useEffect(() => {
    let subscription: ZenObservable.Subscription | undefined

    if (platformId > 0) {
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          const {
            data: {
              listFbAppStoreStatuss: { items, nextToken },
            },
          } = (await API.graphql(
            graphqlOperation(listFbAppStoreStatuss, {
              owner: user.username,
              filter: {
                crmId: { eq: platformId.toString() },
              },
            })
          )) as GraphQLResult

          if (items.length === 0) {
            await API.graphql(
              graphqlOperation(createFbAppStoreStatus, {
                input: {
                  owner: user.username,
                  crmId: platformId.toString(),
                  status: AppStoreStatus.PLATFORMAUTHENTICATION,
                },
              })
            )
          } else {
            // I just take the first. (A duplicate should not exists...)
            let oldStatus = items[0].status
            if (oldStatus === AppStoreStatus.PUBLISH) {
              oldStatus = AppStoreStatus.PLATFORMAUTHENTICATION
            }
            setStatus(oldStatus)
          }

          subscription = await retrieveStatusAndSubscribe(user.username) //
        })
        .catch((err) => {
          console.warn('Subscription error', err)
        })
    }

    return () => {
      if (subscription !== undefined) {
        subscription.unsubscribe()
      }
    }
  }, [platformId])

  // NELLO SWITCH if (message == 'Complete-Oauth2') { inviare post message doPostMessage(Messages.PlatformAuthorized);
  // oppure       doPostMessage(Messages.PlatformError); in caso di errore

  const getPageComponent = () => {
    switch (status) {
      case AppStoreStatus.PLATFORMCONFIGURATION:
        return <PlatformConfiguration steps={steps} />
      case AppStoreStatus.SPREADSHEETSELECTION:
        return <SpreadSheetSelection steps={steps} />
      case AppStoreStatus.STATUS_SEGMENTATION_SELECTION:
        return <SpreadSheetSelection steps={steps} />
      case AppStoreStatus.PUBLISH:
        return <SyncData />
      case AppStoreStatus.NONE:
      case AppStoreStatus.PLATFORMAUTHENTICATION:
        return (
          <PlatformAuthentication
            setOpen={setOpen}
            setAPIresponse={setAPIresponse}
          />
        )
      default:
        return (
          <>
            <div style={{ minHeight: '100vh' }}>
              <Dimmer active={!open} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            </div>
          </>
        )
    }
  }

  const errorObject: any = LangFbAppStore[APIresponse.errorCode]
    ? LangFbAppStore[APIresponse.errorCode]
    : { title: '', description: '', helpers: [] }

  return (
    <>
      <FbTopBar>
        <FbStepGroup isLoading={isLoading} status={status} steps={steps} />
      </FbTopBar>
      <Container>
        <div style={{ maxWidth: '850px', margin: 'auto' }}>
          <Modal open={open}>
            <Modal.Header>{errorObject.title}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div
                  dangerouslySetInnerHTML={{ __html: errorObject.description }}
                />
                <br />
                <Message warning>
                  <Message.Header>
                    <Icon name={'warning sign'} />
                    You can update your settings to:
                  </Message.Header>
                  <List>
                    {errorObject.helpers.map((item: any) => {
                      return (
                        <List.Item>
                          <List.Content>
                            <List.Header>{item.title}</List.Header>
                            <List.Description>
                              {item.description}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                </Message>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                as={'a'}
                href={APIresponse.errorLink}
                target='_blank'
              >
                Go to Configuration Page
              </Button>
            </Modal.Actions>
          </Modal>
          {getPageComponent()}
        </div>
      </Container>
    </>
  )
}

export default FbAppStore

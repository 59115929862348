import axios, { AxiosResponse } from 'axios'

export type CognitoProvider = {
  CreationDate: string
  LastModifiedDate: string
  ProviderName: string
  ProviderType: string
}

export default class CognitoApiService {
  public static federatedToCognitoUrl: string =
    '/federated/federatedToCognitoUser'

  public static describeIdentityProviderUrl: string =
    '/cognitoAPI/DescribeIdentityProvider'

  public static listIdentityProvidersUrl: string =
    '/cognitoAPI/ListIdentityProviders'

  static listIdentityProviders = async (): Promise<{
    Providers: CognitoProvider[]
  }> => {
    return await axios
      .get(
        process.env.REACT_APP_LAMBDA_GATEWAY_API +
          CognitoApiService.listIdentityProvidersUrl
      )
      .then((res: AxiosResponse) => {
        return res.data
      })
  }

  static describeIdentityProvider = async (name: string): Promise<any[]> => {
    return await axios
      .post(
        process.env.REACT_APP_LAMBDA_GATEWAY_API +
          CognitoApiService.describeIdentityProviderUrl,
        { name: name }
      )
      .then((res: AxiosResponse) => {
        return res.data
      })
  }

  static federatedToCognito = async (
    token: string,
    provider: 'FACEBOOK' | 'GOOGLE',
    email: string,
    requestUri: string
  ): Promise<any> => {
    return await axios
      .post(
        process.env.REACT_APP_LAMBDA_GATEWAY_API +
          CognitoApiService.federatedToCognitoUrl,
        {
          id_token: token,
          provider: provider,
          email: email,
          requestUri: requestUri,
        }
      )
      .then((res: AxiosResponse) => {
        return res.data
      })
  }
}

import React from 'react'
import { SemanticOverwrites } from '@app/styles/modules/SemanticOverwrites.styled'
import { ClassNameUtils } from '@app/styles/modules/Utilities.styled'
import { AnimationsUtils } from '@app/styles/modules/Animations.styled'
import { CommonCss } from '@app/styles/modules/CommonCss.styled'

export const GlobalAppStyle: React.FC = () => {
  return (
    <>
      <CommonCss />
      <SemanticOverwrites />
      <ClassNameUtils />
      <AnimationsUtils />
    </>
  )
}

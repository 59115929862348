export const LangFbAppStore: any = {
  popupLocked:
    'Your browser is blocking popups, please allow them to proceed: More info: https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting',

  101: {
    title: 'Initial configuration required',
    description:
      'The platform encountered an error retrieving information from Facebook. <br/> To be able to continue the configuration, you need to create an initial setting (on the Business Manager page).',
    helpers: [
      {
        title: 'Manage access to external data in Facebook',
        description: 'Control how Facebook imports data from apps.',
      },
      {
        title: 'Manage data connections',
        description:
          'Determine whether apps can import data to Facebook on a continuous basis.',
      },
    ],
  },

  messages: {
    tokenError:
      'hey something happened and we are not able to properly connect to FB. Sometimes (only the 1st time) this happens and it’s normal, can you please Retry and enjoy?!',
  },
}

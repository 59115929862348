import { Alert, Toast } from 'LeadsBridgeApp'
import { CognitoUserInterface } from '@aws-amplify/ui-components'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SEND_TOAST = 'SEND_TOAST'
export const SEND_ALERT_MESSAGE = 'SEND_ALERT_MESSAGE'
export const CLOSE_ALERT_MESSAGE = 'CLOSE_ALERT_MESSAGE'
export const SET_LOGIN_LAYOUT = 'SET_LOGIN_LAYOUT'
export const SET_PATH = 'SET_PATH'
export const RESET_PATH = 'RESET_PATH'
export const SET_TRACK_SIGNIN = 'SET_TRACK_SIGNIN'
export const TRIGGER_CONNECT_ACCOUNT_POPUP = 'TRIGGER_CONNECT_ACCOUNT_POPUP'
export const RESET_TRIGGER_CONNECT_ACCOUNT_POPUP =
  'RESET_TRIGGER_CONNECT_ACCOUNT_POPUP'

type LoginLayout = 'generic' | 'partner' | 'bridge-by-url'

export interface ApplicationConfigurationState {
  displayVerticalSidebar: boolean
  toast: Toast | null
  alert: Alert | null
  user: CognitoUserInterface | undefined
  loginLayout: LoginLayout | null
  trackSignIn: boolean
  integrationId: number | null
  path: string | null
}

interface ToggleVerticalSidebar {
  type: typeof TOGGLE_SIDEBAR
}

interface SendToast {
  type: typeof SEND_TOAST
  payload: Toast
}
interface SendAlertMessage {
  type: typeof SEND_ALERT_MESSAGE
  payload: Alert
}

interface CloseAlertMessage {
  type: typeof CLOSE_ALERT_MESSAGE
}
interface SetLoginLayout {
  type: typeof SET_LOGIN_LAYOUT
  payload: string
}

interface SetPath {
  type: typeof SET_PATH
  payload: string
}

interface ResetPath {
  type: typeof RESET_PATH
}

interface SetTrackSignIn {
  type: typeof SET_TRACK_SIGNIN
  payload: boolean
}

interface TriggerConnectAccountPopup {
  type: typeof TRIGGER_CONNECT_ACCOUNT_POPUP
  payload: number
}

interface ResetTriggerConnectAccountPopup {
  type: typeof RESET_TRIGGER_CONNECT_ACCOUNT_POPUP
}

export type ApplicationConfigurationTypes =
  | ToggleVerticalSidebar
  | SendToast
  | SendAlertMessage
  | SetLoginLayout
  | CloseAlertMessage
  | SetPath
  | ResetPath
  | SetTrackSignIn
  | TriggerConnectAccountPopup
  | ResetTriggerConnectAccountPopup

import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { PersistentSessionStorageKeys } from '@app/enums/persistentLocalStorageKeys'
import { Loader } from '@components/Basic/Loader'
import { useGetIntegrationByLegacyId } from '@app/api/getIntegrationByLegacyId'
import { Message } from '@components/Basic/Message'
import { parseApiError } from '@app/api/utils/error'
import { appRoutes } from '@app/routes'
import { trackEvent } from '@app/dataTracking'

export const IntegrationAuthCallback: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  // preparing data required to proceed
  const legacyIntegrationId = queryString.parse(location.search)
    .instanceId as string
  const instanceType = queryString.parse(location.search).instanceType as string
  const appId = sessionStorage.getItem(
    PersistentSessionStorageKeys.integrationAuthFlowAppId
  )
  const legacyIntegrationError = queryString.parse(location.search).error

  // getting integration id from legacyId params
  const params =
    appId && legacyIntegrationId && instanceType
      ? {
          appId: appId,
          instanceId: legacyIntegrationId,
          instanceType: instanceType,
        }
      : undefined
  const { data: integrationData, error: getIntegrationApiError } =
    useGetIntegrationByLegacyId(params)

  useEffect(() => {
    if (integrationData && integrationData.id && appId) {
      trackEvent({
        eventName: 'AuthorizeSuccess',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: parseInt(appId, 10),
          integrationId: integrationData.id,
          integrationName: integrationData.name,
          oauth: true,
        },
      })

      // redirect to IntegrationAuthScreen as edit integration and step 2 active (name selection)
      history.push(
        appRoutes.integrationAuthScreenEditStep2.makeUrl({
          integrationId: `${integrationData.id}`,
          appId: appId,
        })
      )
    }
  }, [integrationData])

  useEffect(() => {
    if (!appId || !legacyIntegrationId || getIntegrationApiError) {
      const errorCode = getIntegrationApiError
        ? parseApiError(getIntegrationApiError).code
        : 'UI-Error'
      const errorDescription = getIntegrationApiError
        ? parseApiError(getIntegrationApiError).message
        : 'Missing appId or legacyIntegrationId'

      trackEvent({
        eventName: 'AuthorizeFailure',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: appId ? parseInt(appId, 10) : undefined,
          integrationId: integrationData?.id,
          integrationName: integrationData?.name,
          oauth: true,
          custom: {
            errorCode,
            errorDescription,
          },
        },
        sendEventToIntercom: true,
      })
    }
  }, [appId, legacyIntegrationId, getIntegrationApiError])

  if (!appId) {
    return (
      <Message $status={'error'}>
        App ID not found. This probably means you have not started the
        Authorization flow from a trusted source (eg. NBEE) or session storage
        is empty.
      </Message>
    )
  }

  if (!legacyIntegrationId) {
    return (
      <Message $status={'error'}>
        {legacyIntegrationError || 'We could not read instanceId from URL.'}
      </Message>
    )
  }

  return getIntegrationApiError ? (
    <Message $status={'error'}>
      {parseApiError(getIntegrationApiError).message}
    </Message>
  ) : (
    <div
      style={{
        position: 'relative',
        height: 200,
      }}
    >
      <Loader $active $dimmer $size={'large'} />
    </div>
  )
}
